"use client";

/* eslint-disable react/no-invalid-html-attribute */
import {colors} from "@c10h/colors";
import DynamicBanner from "@components/DynamicBanner";
import {DropdownMenuItem} from "@components/Nav/DropdownMenuItem";
import {trackNavLinkClick} from "@components/Nav/utils";
import Link from "next/link";
import {useTranslation} from "@i18n/client";
import React, {PropsWithChildren, useCallback, useState} from "react";
import {onKeyHandler} from "src/useEscape";
import {transparent} from "src/utils/transparent";

import {hasDropDownMenu, useHeaderConstants} from "../../../constants/NavConstants";
import {getLoginRedirectUrl} from "../../../utils/getRedirectUrl";
import {useDialog} from "../components/Dialog/useDialog";
import Fade from "../Transitions/Fade";
import Drawer from "./Drawer";
import {NavLanguageSelector} from "./NavLanguageSelector";
import {useNavState} from "./useNavState";
import {NavTheme} from "./types";
import {useIsMobile} from "src/utils/isMobile";
import useNextPagesPaths from "../../../hooks/app-pages-transition/useNextPagesPaths";
import Image from "next/image";
import logo from "public/static/img/v5/logo.svg";
import {LocationForDrawer} from "./useDrawerRegions";
import {FeatureFlag} from "@services/featureFlagConstants";
import {useFeatureFlagEvaluation} from "src/queries/useFeatureFlagEvaluation";
import {useTwMediaQuery} from "src/hooks/useTwMediaQuery";
import {Breakpoint} from "src/constants/breakpoint";
import LoginButton from "@components/Nav/LoginButton";

const nonTransparentPaths = [
  "/locations",
  "/locations/near-me",
  "/locations-dev",
  "/locations-dev/near-me",
];
const lightThemePaths = ["/", "/app-directory-dev"];

export type NavProps = {
  locations: LocationForDrawer[];
  theme?: NavTheme;
  startTransparent?: boolean;
};

const Nav = ({
  locations,
  theme = NavTheme.DEFAULT,
  startTransparent = true,
}: NavProps): React.ReactElement => {
  const isMobile = useIsMobile();
  const isXs = !useTwMediaQuery(Breakpoint.XS);
  const i18n = useTranslation();
  const {pathname} = useNextPagesPaths();

  // @ts-expect-error TS2345: Argument of type 'null' is not assignable to parameter of type 'number | (() => number)'.
  const [openMenuIndex, setOpenMenuIndex] = useState<number>(null);
  // @ts-expect-error TS2345: Argument of type 'null' is not assignable to parameter of type 'number | (() => number)'.
  const [hoveredIndex, setHoveredIndex] = useState<number>(null);
  const [timeoutId, setTimeoutId] = useState(null); // Used to prevent a react bug with onBlur
  const [isNavHovered, setIsNavHovered] = useState(false);

  const config = useHeaderConstants(locations);

  const {center} = config.config;

  // If the pathname is not in the list, then we should not start transparent
  const newStartTransparent = !nonTransparentPaths.includes(pathname) && startTransparent;

  // If the pathname is in the list, then we should use the light theme
  const newTheme = lightThemePaths.includes(pathname) ? NavTheme.LIGHT : theme;

  const styleState = useNavState(newStartTransparent, newTheme, isNavHovered);

  const {
    activeStyle: {transition, transitionClass, ...activeStyle},
    showNav,
  } = styleState;

  const toggleHoveredIndex = useCallback(
    (number?: number, shouldToggleMenu?: "on" | "off" | "opposite") => () => {
      if (Number.isInteger(number)) {
        // @ts-expect-error TS2345: Argument of type 'number | undefined' is not assignable to parameter of type 'SetStateAction<number>'.
        setHoveredIndex(number);
      } else {
        // @ts-expect-error TS2345: Argument of type 'null' is not assignable to parameter of type 'SetStateAction<number>'.
        setHoveredIndex(null);
      }

      if (shouldToggleMenu) {
        if (Number.isInteger(number) && shouldToggleMenu === "on") {
          // @ts-expect-error TS2345: Argument of type 'number | undefined' is not assignable to parameter of type 'SetStateAction<number>'.
          setOpenMenuIndex(number);
        } else if (shouldToggleMenu === "off") {
          // @ts-expect-error TS2345: Argument of type 'null' is not assignable to parameter of type 'SetStateAction<number>'.
          setOpenMenuIndex(null);
        } else if (shouldToggleMenu === "opposite") {
          const val = openMenuIndex === number ? null : number;
          // @ts-expect-error TS2345: Argument of type 'number | null | undefined' is not assignable to parameter of type 'SetStateAction<number>'.
          setOpenMenuIndex(val);
        }
      }
    },
    [openMenuIndex],
  );

  const renderLangSelectorButton = useCallback(
    ({selectedItem, ...rest}: {selectedItem: string}) => (
      <button
        {...rest}
        className={`br8 ph4 pv3 brdn fs14 lh1 cp bg-transparent aria-focus ${transitionClass}`}
        onClick={() => trackNavLinkClick("Languages", "Navbar", selectedItem, "")}
        data-cy="language-switcher-desktop"
      >
        <span
          style={{
            color: activeStyle.anchorColor,
          }}
          className={`cIcon-globe text-lg leading-none ${transitionClass}`}
          aria-hidden
        />
        <span className="visually-hidden">
          {i18n.t("Language: {{language}}", {language: selectedItem})}
        </span>
      </button>
    ),
    [activeStyle.anchorColor, i18n, transitionClass],
  );

  const {
    data: {[FeatureFlag.WEBSITE_SHOW_BOOK_BTN_ON_NAV]: showBookButton},
  } = useFeatureFlagEvaluation({[FeatureFlag.WEBSITE_SHOW_BOOK_BTN_ON_NAV]: false});

  const {state: dialog, Dialog} = useDialog({
    title: i18n.t("Menu"),
    isFullWidth: true,
    isTitleHidden: true,
    blurBackdrop: false,
  });
  return (
    <Fade in={showNav} timeout={transition}>
      <div
        style={{
          backgroundColor: activeStyle.backgroundColor,
          maxWidth: 1900,
          zIndex: 100,
          boxShadow: activeStyle.boxShadow,
        }}
        className={`pos-f w100p brdb brd-transparent ${transitionClass}`}
        onMouseEnter={() => (isMobile ? null : setIsNavHovered(true))}
        onMouseLeave={() => (isMobile ? null : setIsNavHovered(false))}
        onFocus={() => (isMobile ? null : setIsNavHovered(true))}
        onBlur={() => (isMobile ? null : setIsNavHovered(false))}
      >
        <DynamicBanner />

        <div className="df jcsb df aic pl8 pr4 ph3-lg ph4-xs minh20 minh14-sm d">
          {/* LOGO */}
          <Link
            href="/"
            rel="home"
            className="pos-r zIndex1"
            style={{
              flexShrink: 0,
            }}
            data-cy="carbon-logo"
          >
            <Image
              className={`contrast-saturate t-${transition / 2}`}
              height={26}
              width={186}
              src={logo}
              alt="Carbon Health"
              style={{
                filter: activeStyle.logoFilter || null,
              }}
            />
          </Link>

          {/* MIDDLE */}
          <nav aria-label={i18n.t("Site Navigation")} className="fx maxw52 dn-f-md">
            <ul className="df jcc fx fw aic pos-r zIndex2">
              {center.map((item, i) => {
                const isHovered = i === hoveredIndex;
                const {anchorColor} = activeStyle;

                const titleColor =
                  Number.isInteger(hoveredIndex) && !isHovered
                    ? transparent(anchorColor, 75)
                    : anchorColor;

                // Is the user on the url of the current menu item
                const isActive = hasDropDownMenu(item)
                  ? false
                  : item.href === pathname && openMenuIndex === null && hoveredIndex === null;

                const handleFocus = () => {
                  // @ts-expect-error TS2769: No overload matches this call.
                  clearTimeout(timeoutId);
                  toggleHoveredIndex(i)();
                };

                const isOpenAndHovered = openMenuIndex === i && isHovered;
                const handleClick = (value: string) =>
                  trackNavLinkClick(item.label, "Navbar", value, item.title);

                const hoverEffects = {
                  onMouseEnter: toggleHoveredIndex(i, "on"),
                  onFocus: handleFocus,
                  // @ts-expect-error TS2345: Argument of type 'null' is not assignable to parameter of type 'number | undefined'.
                  onMouseLeave: toggleHoveredIndex(null, "off"),
                  onBlur: () => {
                    setTimeoutId(
                      // @ts-expect-error TS2345: Argument of type 'Timeout' is not assignable to parameter of type 'SetStateAction<null>'.
                      setTimeout(() => {
                        // @ts-expect-error TS2345: Argument of type 'null' is not assignable to parameter of type 'number | undefined'.
                        toggleHoveredIndex(null, "off")();
                      }),
                    );
                  },
                  // @ts-expect-error TS2345: Argument of type 'null' is not assignable to parameter of type 'number | undefined'.
                  onKeyDown: onKeyHandler("Escape", toggleHoveredIndex(null, "off")),
                };

                const circle = (
                  <NavCircle
                    className="w100p pos-a left0 right0"
                    style={{bottom: "1rem"}}
                    isHovered={isHovered || isActive}
                  />
                );

                return (
                  <li
                    key={item.title}
                    // Distribute the menu items evenly with padding (flex-1) instead of justify-content: space-between
                    // so that the menu items don't jump around when the user hovers over them
                    className="df fdc aic p0 h20 flex-1"
                    {...hoverEffects}
                    data-cy="menu-parent"
                  >
                    {hasDropDownMenu(item) ? (
                      <>
                        <span
                          className={`wsnw pv8 df aic jcc font-cm fs16 lh1 h100p w100p focus-bsDarkBlue3 `}
                        >
                          <button
                            className={`br8 pos-r bg-inherit brdn font-cm p0 m0 cp ${transitionClass}`}
                            onClick={toggleHoveredIndex(i, "opposite")}
                            style={{color: titleColor}}
                            aria-expanded={isOpenAndHovered}
                            aria-controls={`${item.id}-dropdown`}
                          >
                            {item.title}
                            {circle}
                          </button>
                          <span
                            className={`cIcon-icon_arrow_dropdown ml1 ${transitionClass}`}
                            style={{color: titleColor}}
                            aria-hidden
                          />
                        </span>
                        {/*Always (Open) Render on SSR for SEO*/}
                        {/*Dont use Fade since it Transtion doesn't render children on SSR*/}
                        <div className={isOpenAndHovered ? "" : "hidden"}>
                          <DropdownMenuItem
                            item={item}
                            onFocus={handleFocus}
                            onClick={(value: string) => handleClick(value)}
                          />
                        </div>
                      </>
                    ) : (
                      <Link href={item.href} legacyBehavior>
                        <span className="wsnw cp focus-bsDarkBlue3 fs16 lh1 font-cm pv8">
                          <a
                            className={`br8 pos-r ${transitionClass}`}
                            style={{color: titleColor}}
                            onClick={() => handleClick(item.title)}
                            href={item.href as string}
                            data-text={item.title}
                          >
                            {item.title}
                            {circle}
                          </a>
                        </span>
                      </Link>
                    )}
                  </li>
                );
              })}
            </ul>
          </nav>

          {/* RIGHT AND DRAWER */}
          <ul className="dg aic gap-2">
            <LoginButton
              isNavHovered={isNavHovered}
              activeStyle={activeStyle}
              transitionClass={transitionClass}
              showBookButton={showBookButton}
            />
            <li className="dn-lg">
              <NavLanguageSelector
                anchorDirection="bottom right"
                renderButton={renderLangSelectorButton}
              />
            </li>
            {/* Menu Button */}
            <li className="dn db-lg">
              <button
                className={`gray800 brdn font-im fs14 lh12 br8 ph4 pv3 cp aria-focus ${transitionClass}`}
                style={{
                  backgroundColor: activeStyle.buttonBackground,
                }}
                aria-label={i18n.t("Open Menu")}
                onClick={() => {
                  dialog.open();
                  trackNavLinkClick("Menu", "Navbar", "Menu", "Menu");
                }}
                data-cy="menu"
              >
                {i18n.t("Menu")}
              </button>
              <Dialog>
                <Drawer
                  closeDrawer={dialog.close}
                  locations={locations}
                  shouldDisplayBookButton={showBookButton}
                  shouldEvaluteBookButtonFlag={isXs}
                />
              </Dialog>
            </li>
          </ul>
        </div>
      </div>
    </Fade>
  );
};

interface LoginProps {
  isNavHovered: boolean;
  backgroundColor: string;
  // @ts-expect-error TS7010: 'onClick', which lacks return-type annotation, implicitly has an 'any' return type.
  onClick();
}
export const Login: React.FC<PropsWithChildren<LoginProps>> = ({
  children,
  isNavHovered,
  backgroundColor,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const color = isNavHovered
    ? isHovered
      ? transparent(colors.gray800, 20)
      : transparent(colors.gray800, 10)
    : backgroundColor;

  const sharedProps = {
    onMouseEnter: () => setIsHovered(true),
    onMouseLeave: () => setIsHovered(false),
    onClick,
    href: getLoginRedirectUrl(),
  };

  return (
    <a
      className="wsnw ph4 pv3 brdn br8 font-im fs14 lh12 gray800 hover-gray800 hover-bg-gray200 t-300 hidden lg:block"
      style={{backgroundColor: color}}
      {...sharedProps}
    >
      {children}
    </a>
  );
};

type NavCircleProps = React.HTMLAttributes<HTMLSpanElement> & {
  isHovered: boolean;
};
export const NavCircle: React.FC<NavCircleProps> = ({isHovered, className = "", style}) => (
  <span className={`ma minh4 df jcc aic ${className}`} style={style} aria-hidden>
    <svg
      height={6}
      width={6}
      className="t-150"
      style={{
        visibility: isHovered ? "visible" : "hidden",
        opacity: isHovered ? 1 : 0,
      }}
    >
      <circle cx="50%" cy="50%" r="45%" strokeWidth="3" fill={colors.darkerMint} />
    </svg>
  </span>
);

export default React.memo(Nav);
